.container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
}

.slider,
.cover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.image {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px;
}

.langIcon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px; 
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65%;
  width: 100%;
}
/* this is the logo style depend on which company is selected inside variables file */
.veritas {
  width: 160%;
  max-width: 800px;
  height: auto;
}

.speedyRecovery {
  position: absolute;
  top: 30px;
  width: 180px;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .image,
  .langIcon {
    padding: 30px;
  }

  .logo {
    max-width: 400px;
  }

  .form {
    width: 100%;
    padding: 0 20px;
  }

  .slider {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .logo {
    max-width: 400px;
  }

  .image,
  .langIcon {
    padding: 30px;
  }

  .form {
    width: 100%;
    padding: 0 10px;
  }
}