*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Clash Grotesk', sans-serif;
}
 ::-webkit-scrollbar {
   width: 8px;
   height: 6px;
 }

 ::-webkit-scrollbar-track {
   border-radius: "10px";
   background: rgba(36, 36, 39, 0.1);
 }
 ::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: rgba(1, 2, 2, 0.2);
 }

 ::-webkit-scrollbar-thumb:hover {
   background: rgba(4, 27, 27, 0.3);
 }