.card_container {
  width: 400px;
  height: 600px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  border: 1px solid #4ABBA7;
  border-radius: 15px;
  box-shadow: none;
  z-index: 1;
  background-color: #FBFBFB;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card_container_open {
  transform: translateY(0);
  opacity: 1;
  box-shadow: 0 0 5px #4ABBA7;
}

.card_header {
  background-color: #4ABBA7;
  height: 60px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.avatar_title {
  display: flex;
  align-items: center;
}

.header_avatar {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  box-shadow: 0 0 5px white;
  border-radius: 50%;
  margin-left: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-left: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.icon {
  margin-right: 10px;
  color: white;
  cursor: pointer;
}

.menu {
  margin-left: -15px;
}

.menu_container {
  padding: 5px;
}

.menu_item {
  font-weight: 500;
}

.menu_icon {
  margin-right: 10px;
}

.bot_avatar_box {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.bot_avatar_box_hide {
  visibility: hidden;
}

.bot_avatar {
  width: 100px;
  cursor: pointer;
  transition: 0.5s;
}

.bot_avatar:hover {
  transform: scale(1.2);
}

.chat_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  background-color: #f7f7f7;
  margin-bottom: 90px;
}

.message_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.bot_message_box {
  flex-direction: row;
}

.user_message_box {
  flex-direction: row-reverse;
}

.message_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bot_message_avatar {
  margin-right: 10px;
}

.user_message_avatar {
  margin-left: 10px;
}

.message {
  padding: 6px 15px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 4px;
}

.bot_message {
  background-color: #e5e5ea;
  color: #000;
}

.user_message {
  background-color: #1269ba;
  color: #fff;
}

.loading_message_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.loading_message_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message_box {
  display: flex;
  align-items: flex-start;
  align-self: flex-end;
  margin-bottom: 10px;
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  background-color: #4ABBA7;
}

.input_box {
  background-color: white;
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  width: 85%;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 20px;
  resize: none;
}

.submit {
  transition: 0.5s;
  cursor: pointer;
}

.submit:hover {
  color: #4ABBA7;
}

.disabled {
  color: #625a5a;
  pointer-events: none;
}

.bot_message :global(h1),
.bot_message :global(h2),
.bot_message :global(h3),
.bot_message :global(h4),
.bot_message :global(h5),
.bot_message :global(h6) {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 550;
}

.bot_message :global(p) {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
}

.bot_message :global(code) {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}

.bot_message :global(pre) {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.bot_message :global(ul),
.bot_message :global(ol) {
  margin: 10px 0;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
}

.bot_message :global(blockquote) {
  border-left: 4px solid #ddd;
  margin: 10px 0;
  padding-left: 10px;
  color: #666;
}

.bot_message :global(table) {
  border-collapse: collapse;
  margin: 10px 0;
}

.bot_message :global(th),
.bot_message :global(td) {
  border: 1px solid #ddd;
  padding: 8px;
}
