.container {
  padding: 20px 32px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-bottom: 32px;
  position: relative;
}

.form_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.control {
  flex-basis: 100%;
}

@media (min-width: 900px) {
  .control {
    flex-basis: 32%;
  }
}

.control label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.control input,
.control textarea,
.control select {
  display: block;
  font: inherit;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.control input:focus,
.control textarea:focus {
  outline: 1px solid var(--primary-color);
}

.required {
  color: red;
  font-size: 20px;
}

.searchable {
  flex-basis: 100%;
}

@media (min-width: 900px) {
  .searchable {
    flex-basis: 32%;
  }
}

.searchable label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.actions_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.actions  {
  margin-top: 56px;
}

.actions_add button {
  font: inherit;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 84px;
  border-radius: 5px;
  border: none;
}

.actions_clear button {
  font: inherit;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  background-color: #565656;
  color: white;
  padding: 10px 84px;
  border-radius: 5px;
  border: none;
}

.actions_clear button:hover,
.actions_clear button:active {
  background-color: #454545;
}

.actions_add button:hover,
.actions_add button:active {
  background-color: var(--primary-color-dark);
}

.alert {
  color: rgb(228, 0, 0);
  padding: 32px 24px;
  width: 100%;
  background-color: rgb(255, 214, 222);
  border-radius: 10px;
  height: 100%;
}

.alert p{
  font-size: 16px;
  padding: 8px;
}

.alert p span{
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  margin-right: 2px;
}

.helperText {
  font-size: 12px;
  color: red;
  margin-top: 4px;
  margin-left: 8px;
}

button:disabled {
  opacity: 0.6; 
  cursor: not-allowed; 
}


.select_placeholder {
  color: #999999; 
  font-style: italic; 
}

.select_options {
  color: #000;
}

input::placeholder {
  color: #858B8B;
}

.control input:disabled {
  opacity: 0.5;
}

.title {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #4ABBA7;
  display: 'inline-block';
  padding-bottom: 10px;
  width: fit-content;
}

.space{
  margin: 40px 0;
}

.hide {
  visibility: hidden;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}