.container {
    padding: 30px;
    width: 70%;
}

.change_password_box {
    width: 83%;
}

@media (max-width: 1300px) {
    .container {
        padding: 30px;
        width: 100%;
    }
}