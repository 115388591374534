.container {
    padding: 10px;
    padding-bottom: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 1px;
}

.pie_box {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.pie_chart {
    width: 60%;
    display: flex;
    justify-content: space-around;
}

.bar_box {
    height: 300px;
    display: flex;
    justify-content: center;
}

.bar_chart {
    width: 70%;
    position: relative;
}

@media (max-width: 1450px) {
    .pie_box {
        flex-direction: column;
        align-items: center;
    }
    .pie_chart {
        width: 50%;
    }
}
