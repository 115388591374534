.outer_container {
  position: relative;
    width: 55vw;
    height: 100%;
    display: none;
}
    @media (min-width: 900px) {
      .outer_container {
      display: block;
    }
}
.slider_container{
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;
  height: 100% !important;
}