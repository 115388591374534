.increment-input-container {
    margin-left: 10px;
}

/* Hide the spinner controls for number input */
.increment-input[type="text"]::-webkit-outer-spin-button,
.increment-input[type="text"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.increment-input[type="text"] {
    padding: 3px 4px;
    border: none;
    outline: none;
    box-sizing: none;
    text-align: center;
    font-weight: 500;
}

.increment-button,
.decrement-button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 3px 8px;
    margin: 0;
    font-weight: bold;
}

.increment-button {
    border-radius: 0 5px 5px 0;
}

.decrement-button {
    border-radius: 5px 0 0 5px;
}
