.upload_files_box {
  margin-bottom: 100px;
}

.container {
  display: flex;
}
.row {
  width: 50%;
}

.indicator_buttons_Box {
  display: flex;
  margin-bottom: 10px;
}

.title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 2px solid #4ABBA7;
  display: inline;
  padding-bottom: 10px;
}
.drag_zone_title {
  font-size: 20px;

}
.selected_file {
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFAD60;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
}

.file_box {
    display: flex;
}

.drag {
    border: 1px dashed rgb(168, 168, 168);
    border-width: 2px;
    border-radius: 10px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.5s;
    position: relative;
}
.cloud{
    color: rgb(168, 168, 168);
}

.upload {
    width: 40%;
    margin-left: 30px;
}

.facility_info_select {
    width: 100%;
    margin-bottom: 20px;
  }

.browse_file_input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  }

  .stepper {
    width: 65%;
    margin: 30px 0;
    margin-left: -16px;
  }