.container {
    padding: 10px 30px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 1px;
    position: relative;
}

.search_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 24px 0 44px;
}

.search_by {
    flex-basis: 20%;
}

.search_button {
    flex-basis: 180px;
    padding: 6px;
    max-height: 40px;
}

.state_box {
    display: flex;
    flex: 1;
}

.state_button {
    margin: 0 10px;
    background-color: aqua;
}

.pie_box {
    display: flex;
    justify-content: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap;
}

.pie_chart {
    width: 35%;
    margin: 0 80px;
}

.bar_box {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.bar_chart {
    width: 90%;
    position: relative;
    padding-top: 60px;
}

@media (max-width: 1450px) {
    .pie_box {
        flex-direction: column;
        align-items: center;
    }
    .pie_chart {
        width: 50%;
    }
}
